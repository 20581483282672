<template>
  <div>
    <a-row>
      <a-col :span="17" class="iss-main">
        <a-row class="left">
          <a-col :span="19">
            <div class="avatarMessage">
              <div class="avatarleft">
                <div class="headImg">
                  <a-avatar
                    v-if="form.avatar"
                    :src="setAvatar(form.avatar)"
                    :size="104"
                  />
                  <div v-else class="headerText">
                    {{ form.name[0] }}
                  </div>

                  <div class="maskContact">
                    <iss-image-upload
                      list-type="picture-card"
                      accept=".jpe,.jpeg,.jpg,.png"
                      v-model:value="activeUpload"
                      class="imageUpload mask-icon"
                      @file="fileData"
                    />
                  </div>
                </div>
              </div>

              <div class="avatarRight">
                <!-- layout="vertical" -->
                <a-form>
                  <a-form-item label="" class="nameClass">
                    <a-input
                      v-model:value="form.name"
                      placeholder="请输入姓名"
                      style="width: 99%"
                      @blur="handleBlur('name')"
                    />
                  </a-form-item>

                  <a-form-item label="" class="positionClass">
                    <a-textarea
                      v-model:value="form.describe"
                      placeholder="请输入备注信息"
                      @blur="handleBlur('describe')"
                    />
                  </a-form-item>
                </a-form>

                <div class="oneClass">
                  <div class="interaction hudong">
                    <img
                      src="@/assets/images/box/data.png"
                      alt=""
                      class="data-img"
                    />
                    最后互动
                  </div>
                  <!--                     :disabled-time="disabledDateTime"   -->
                  <div class="daysAgo">
<!--                    <a-form-item label="">-->
<!--                      <a-date-picker-->
<!--                        v-model:value="form.lastInteractionTime"-->
<!--                        :getCalendarContainer="-->
<!--                          triggerNode => triggerNode.parentNode-->
<!--                        "-->
<!--                        :disabled-date="disabledDate"-->
<!--                        :disabled-time="disabledDateTime"-->
<!--                        value-format="yyyy-MM-DD HH:mm:ss"-->
<!--                        show-time-->
<!--                        placeholder="请选择"-->
<!--                        style="width: 100%"-->
<!--                        :inputReadOnly="true"-->
<!--                        class="phoneCalssRight"-->
<!--                        @blur="handleBlur('lastInteractionTime')"-->
<!--                      />-->
<!--                    </a-form-item>-->
                    <div class='zuihouhudong'>
                      {{form.lastInteractionTime}}
                    </div>

                  </div>
                  <div class="date">
                    {{ form.day ? form.day : '0' }}天前互动
                  </div>
                </div>

                <div class="twoClass">
                  <div class="frequency">
                    <img
                      src="@/assets/images/box/woshou.png"
                      alt=""
                      class="woshou-img"
                    />

                    联系频次
                  </div>
                  <div class="weekend">
                    <!--   每周 -->
                    <a-form-item label="" class="interaction">
                      <a-select
                        v-model:value="form.frequency"
                        placeholder="请选择"
                        @blur="handleBlur('frequency')"
                      >
                        <a-select-option
                          v-for="item in frequenctList"
                          :key="item.itemCode"
                          :value="item.itemCode"
                        >
                          {{ item.itemNameCn }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </div>
                  <div class="weekendLeft"></div>
                </div>
              </div>
            </div>
          </a-col>
          <!--              <a-col :span="4" class="two">-->

          <!--              </a-col>-->
          <!--              <a-col :span="4" class="three">-->
          <!--                <div class="date">7天前互动(动态)</div>-->
          <!--              </a-col>-->
          <a-col :span="5">
            <!-- <a-select placeholder="请选择" v-model:value="form.value">
              <a-select-option
                v-for="ol in operatorList"
                :key="ol.value"
                :value="ol.value"
              >
                {{ ol.label }}
              </a-select-option>
            </a-select> -->
            <a-dropdown>
              <template #overlay>
                <a-menu>
                  <a-menu-item key="1" @click="deleteContact">
                    <DeleteOutlined />
                    删除联系人
                  </a-menu-item>
                </a-menu>
              </template>
              <a-button>
                操作
                <DownOutlined />
              </a-button>
            </a-dropdown>
          </a-col>
        </a-row>

        <!--          tab  -->
        <div class="tabsClass">
          <a-tabs v-model:activeKey="activeKey">
            <a-tab-pane key="1" tab="待办">
              <reminder-page />
            </a-tab-pane>
            <a-tab-pane key="2" tab="时刻" force-render>
              <tiem-page ref="tiemPage" />
            </a-tab-pane>
          </a-tabs>
          <div
            class="addReminder"
            v-if="activeKey == 1"
            @click="handleAddReminder"
          >
            新增待办
          </div>
          <div class="addReminder" v-else @click="tiemPage.addModalShow">
            新增记录
          </div>
        </div>
      </a-col>

      <a-col :span="6" class="iss-main iss-main-mr">
        <div class="tabsRight">
          <a-tabs v-model:activeKey="activeKeyRight">
            <a-tab-pane key="1" tab="基本信息">
              <iss-information />
            </a-tab-pane>
            <a-tab-pane key="2" tab="详细信息" force-render>
              <iss-details />
            </a-tab-pane>
            <a-tab-pane key="3" tab="相关联系人" force-render>
              <iss-contacts />
            </a-tab-pane>
          </a-tabs>
        </div>
      </a-col>
    </a-row>
  </div>
  <edit-page
    v-model:visible="editPage"
    :init="activeItem"
    @fnOk="handleEditPage"
  />
</template>

<script>
import {
  Avatar,
  Row,
  Col,
  Select,
  Tabs,
  Form,
  // DatePicker,
  message,
  menu,
  dropdown,
  Modal,
} from 'ant-design-vue';
import {
  DeleteOutlined,
  DownOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons-vue';
import { onMounted, reactive, ref, toRefs, watch, createVNode } from 'vue';
// import { UserOutlined } from '@ant-design/icons-vue';
import IssImageUpload from '@/components/imageUpload';
import IssInformation from '@/views/contacts/contactAall/detailsPage/information';
import IssDetails from '@/views/contacts/contactAall/detailsPage/details';
import IssContacts from '@/views/contacts/contactAall/detailsPage/contacts';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import contactAll from '@/api/contactAll';
import { setAvatar } from '@/utils';
import editPage from '@/views/today/homePage/components/editPage';
import reminderPage from '@/views/contacts/contactAall/detailsPage/reminderPage';
import tiemPage from '@/views/contacts/contactAall/detailsPage/tiemPage';
import moment from 'moment/moment';

export default {
  components: {
    // ADrawer: Drawer,
    ARow: Row,
    ACol: Col,
    // UserOutlined,
    AAvatar: Avatar,
    ASelect: Select,
    ASelectOption: Select.Option,
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    IssImageUpload,
    // PlusOutlined,
    IssInformation,
    IssDetails,
    IssContacts,
    AForm: Form,
    AFormItem: Form.Item,
    // ADatePicker: DatePicker,
    editPage,
    reminderPage,
    tiemPage,
    DeleteOutlined,
    DownOutlined,
    ADropdown: dropdown,
    AMenu: menu,
    AMenuItem: menu.Item,
  },
  props: {
    visible: Boolean,
    init: Object,
  },
  setup(props, context) {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    // const { proxy } = getCurrentInstance();
    const { id } = route.params;
    const mainRef = ref();
    const state = reactive({
      visible: false,
      activeKey: '1',
      activeKeyRight: '1',
      frequenctList: [],
      editPage: false,
      activeItem: {},
    });
    const form = reactive({
      userId: '',
      avatar: '',

      value: '操作',
      activeUpload: '',
      frequency: null,
      lastInteractionTime: null,
      name: '',
      describe: '',
      day: '0',
    });
    const clone = reactive({
      perSonalInfoList: {},
    });
    const tiemPage = ref();

    watch(
      () => props.visible,
      value => {
        state.visible = value;
      }
    );

    // 路由变化调取接口页面查询接口
    watch(
      () => route.params.id,
      value => {
        if (value && route.name === 'contactDetails') {
          perSonalInfo();
        }
      }
    );

    contactAll.getTree('', { code: 'FREQUENCY' }).then(res => {
      if (res) {
        state.frequenctList = res;
      }
    });

    const perSonalInfo = () => {
      // console.log('获取联系人信息,头像,姓名,职位等', route.params.id)
      contactAll.getPerSonalInfo('', route.params.id).then(res => {
        if (res) {
          Object.assign(form, res);
          clone.perSonalInfoList = res;
        }
      });
    };
    //区间选择函数
    const range = (start, end) => {
      const result = [];

      for (let i = start; i < end; i++) {
        result.push(i);
      }

      return result;
    };

    const deleteContact = () => {
      Modal.confirm({
        title: `您确定要删除该联系人吗？删除后与该联系人相关的未完成待办中将不再展示该联系人。`,
        icon: createVNode(ExclamationCircleOutlined),
        closable: false,
        okText: '确定',
        cancelButtonProps: null,
        onOk() {
          contactAll
            .deleteContactIds('', {
              userId: store.state.account.user.id,
              contactId: [id],
            })
            .then(res => {
              console.log(res);
              if (res) {
                router.back();
              }
            });
        },
      });
    };

    onMounted(() => {
      perSonalInfo();
    });

    return {
      mainRef,
      id,
      form,
      perSonalInfo,
      setAvatar,
      clone,
      tiemPage,
      deleteContact,
      ...toRefs(state),
      handleClose() {
        context.emit('fnOk', false);
      },
      disabledDate: current => {
        return current && current > moment().subtract(1, 'days');
      },
      disabledDateTime: () => {
        return {
          disabledSeconds: () => range(1, 60),
        };
      },

      // todo
      handleBlur(type) {
        for (let key in clone.perSonalInfoList) {
          if (key === type) {
            if (clone.perSonalInfoList[key] !== form[key]) {
              contactAll
                .contact('', { ...form })
                .then(res => {
                  if (res) {
                    message.success('操作成功');
                  }
                })
                .finally(() => {});
            } else {
              continue;
            }
          }
        }
      },
      fileData(value) {
        form.avatar = value;
        //   调取保存接口

        contactAll
          .contact('', { ...form })
          .then(() => {
            // message.success('操作成功');
          })
          .finally(() => {});
      },
      handleAddReminder() {
        // clone.perSonalInfoList
        let parmas = {
          contactId: [route.params.id],
          comeFrom: 'contact',
          type: 'add',
          ...clone.perSonalInfoList,
        };
        state.editPage = true;
        Object.assign(state.activeItem, parmas);
      },
      handleEditPage(value) {
        state.editPage = value;
        //  todo 新增弹窗完毕后需要刷新页面列表
      },
    };
  },
};
</script>

<style scoped lang="less">
.iss-main {
  height: calc(100vh - 90px);
  overflow: auto;
}
.iss-main-mr {
  padding: 0;
  margin: 16px 0px 0px 0px;
}
.tabsRight /deep/ .ant-tabs-nav .ant-tabs-tab {
  margin: 0px;
  padding: 12px 14px;
}
.left {
  font-size: 12px;
}
.avatarMessage {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 20px;
}
.avatarleft {
  width: 16%;
}
.avatarRight {
  color: #6b7592;
  margin-left: 24px;
  width: 100%;
  width: 84%;
  .oneClass {
    width: 100%;
    .hudong {
      width: 80px;
    }
    .daysAgo {
      width: 200px;
      text-align: right;
      .zuihouhudong {
        text-align: center;
        font-size: 13px;
      }

    }
    .date {
      width: 80px;
      text-align: right;
    }
  }
  .oneClass,
  .twoClass {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .twoClass {
    width: 100%;
    .frequency {
      width: 80px;
    }
    .weekend {
      width: 200px;
      text-align: center;
    }
    .weekendLeft {
      width: 35%;
      text-align: right;
    }
  }
}
.nameClass {
  //color: #3F4557;
  //font-size: 20px;
  margin-bottom: 3px !important;
}
.nameClass /deep/ .ant-input {
  color: #3f4557;
  font-size: 20px;
  //margin-bottom: 8px;
  padding: 0px;
}
.positionClass /deep/ .ant-input {
  color: #3f4557;
  padding: 0px;
  width: 400px;
}
//.interaction {
//  margin: 16px 0px;
//}
.daysAgo {
  width: 65%;
  text-align: right;
  .phoneCalssRight /deep/ .ant-input {
    padding: 0px;
    border: 1px solid #ffffff;
    text-align: center;
    color: #3f4557;
  }
}
//.three {
//  align-items: center;
//  display: flex;
//  align-content: end;
//  flex-wrap: wrap;
//  //justify-content: center;
//  .date {
//    color: #3F4557;
//  }
//}

.two {
  //margin: 0 auto;
  text-align: center;
  align-items: center;
  display: flex;
  align-content: flex-end;
  flex-wrap: wrap;
  .weekend {
    color: #3f4557;
    //margin-top: 16px;
    //margin-right: 30px;
  }
}

.tabsClass {
  position: relative;
  margin-top: 38px;
}
.imageUpload {
  display: none;
}
.avatarleft:hover {
  display: inline-block;
}

.headImg {
  //position: absolute;
  right: 10px;
  cursor: pointer;
}
.maskContact {
  opacity: 0;
  //position: absolute;
  background: rgba(0, 0, 0, 0.4);
  cursor: pointer;
  transition: opacity 0.4s ease 0s;
  .mask-icon {
    font-size: 2rem;
    position: absolute;
    //top: 28px;
    //left: 48px;
    top: 32px;
    left: 36px;
    margin-left: -1rem;
    margin-top: -1rem;
    color: rgb(214, 214, 214);
  }
  &:hover {
    opacity: 1;
  }
}
.maskContact
  /deep/
  .iss-upload.ant-upload-picture-card-wrapper
  .ant-upload.ant-upload-select-picture-card {
  border-radius: 50%;
  border-radius: 60px;
  //border: none;
}

.ant-input {
  background-color: #ffffff;
  border: 1px solid #ffffff;
}
.avatarRight
  /deep/
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: #ffffff;
  border: 1px solid #ffffff;
}
.avatarRight /deep/ .ant-select {
  color: #97a5ce;
}
//.interaction /deep/ .ant-col-16 {
//  max-width: 100%;
//}
.ant-form-item {
  margin-bottom: 0px;
}
.interaction
  /deep/
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border: none;
}
.headerText {
  width: 104px;
  height: 104px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}
.addReminder {
  padding: 8px 12px;
  background: #ff7b00;
  border-radius: 4px;
  font-size: 12px;
  font-weight: normal;
  color: #ffffff;
  text-align: center;
  cursor: pointer;

  position: absolute;
  top: 0px;
  right: 0px;
}
.tabsClass /deep/ .ant-tabs-bar {
  margin: 4px 0px 0px 0px;
  border-bottom: 0.5px solid #eeeeee;
}
</style>
